<div class="dropdown">
  <button
    class="btn btn-secondary dropdown-toggle btn-theme mb-3"
    type="button"
    id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false">
    Actions
  </button>
  <ul class="dropdown-menu list-unstyled sub-menus"
    aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div>