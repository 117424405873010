import { Component } from '@angular/core';

@Component({
  selector: 'app-action-dropdown',
  standalone: true,
  imports: [],
  templateUrl: './action-dropdown.component.html',
  styleUrl: './action-dropdown.component.scss'
})
export class ActionDropdownComponent {

}
